import React, {useRef, useEffect, useState} from "react";
import {
    select,
    line,
    curveCatmullRom,
    scaleLinear,
    scaleTime,
    axisBottom,
    axisLeft,
    axisRight,
    zoom,
} from "d3";
import {Grid} from "@mui/material";
import {extent} from "d3-array";
import "../../../assets/css/graph.css";
import {useTranslation} from "react-i18next";
import {SlGraph} from "react-icons/sl";
import dayjs from "dayjs";

const useResizeObserver = (ref) => {
    const [dimensions, setdimensions] = useState(null);

    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setdimensions(entry.contentRect);
            });
        });
        resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

var div = select("body")
    .append("div")
    .attr("class", "txt1")
    .style("opacity", 0)
    .style("display", "none");
const Graph = ({
                   vegetationIndex,
                   data,
                   datapredict,
                   indexForecast,
                   notifications,
                   pattern,
                   rainData,
                   rain_max,
                   rain_min,
                   patternAlertsDates,
                   ndviForecastAlertsDates,
                   tempData,
                   temp_max,
                   temp_min,
                   gddData,
                   gdd_max,
                   gdd_min,
                   cultureId, patternSelf, selfPatternAlertsDates
               }) => {

    const svgRef = useRef();
    const wrapperRef = useRef();
    const dimensions = useResizeObserver(wrapperRef);
    const [currentZoom, setcurrentZoom] = useState(null);
    const [datagraph, setdatagraph] = useState(null);
    const [datagraphSmooth, setdataGraphSmooth] = useState(null);
    const [dataRangePredict, setdataRangePredict] = useState(null);
    const [dataRangeNotifications, setdataRangeNotifications] = useState(null);
    const [rainMin, setRainMin] = useState(null);
    const [rainMax, setRainMax] = useState(null);
    const [tempMin, setTempMin] = useState(null);
    const [tempMax, setTempMax] = useState(null);
    const [gddMin, setGddMin] = useState(null);
    const [gddMax, setGddMax] = useState(null);
    const {t} = useTranslation();
    const [observedDataLineState, setobservedDataLineState] = useState(false);
    const [smoothedDataLineState, setsmoothedDataLineState] = useState(true);
    const [forecastDataLineState, setforecastDataLineState] = useState(false);
    const [patternLineState, setpatternLineState] = useState(false);
    const [indexForecastLineState, setIndexForecastLineState] = useState(true);
    const [rainDataLineState, setRainDataLineState] = useState(false);
    const [tempDataLineState, setTempDataLineState] = useState(false);
    const [gddDataLineState, setGddDataLineState] = useState(true);
    const [patternSelfLineState, setpatternSelfLineState] = useState(true);

    useEffect(() => {
        if (!data) return;
        setdatagraph(data.data);
        setdataGraphSmooth(data.data_smoothed);
        setRainMin(rain_min);
        setRainMax(rain_max);
        setTempMin(temp_min);
        setTempMax(temp_max);
        setGddMax(gdd_min);
        setGddMax(gdd_max);
    }, [data]);
    useEffect(() => {
        if (!datapredict) return;
        if (!datagraph) return;
        if (!datapredict.length > 0) return;
        if (!datagraph.length > 0) return;
        if (!notifications.length > 0) return;
        const lastDate = dayjs(datagraph[datagraph.length - 1].date);
        const startDate = lastDate.subtract(1, "month");
        const endDate = lastDate.add(4, "month");
        const filteredData = datapredict.filter((data) => {
            const date = dayjs(data.date);
            return date.isAfter(startDate) && date.isBefore(endDate);
        });
        setdataRangePredict(filteredData);
    }, [datapredict, datagraph]);
    useEffect(() => {
        if (!datagraph || !datagraph.length > 0) return;
        if (!notifications || !notifications.length > 0) return;
        const lastDate = dayjs(datagraph[datagraph.length - 1].date);
        const startDate = lastDate.subtract(1, "month");
        const endDate = lastDate.add(2, "month");
        const filteredNotificationsData = notifications.filter((data) => {
            const date = dayjs(data.date);
            return date.isAfter(startDate) && date.isBefore(endDate);
        });
        setdataRangeNotifications(filteredNotificationsData);
    }, [datagraph, notifications]);
    useEffect(() => {
        if (!rainData) return;
        setRainMin(rain_min);
        setRainMax(rain_max);
    }, [rainData]);
    useEffect(() => {
        if (!tempData) return;
        setTempMin(temp_min);
        setTempMax(temp_max);
    }, [tempData]);
    useEffect(() => {
        if (!gddData) return;
        setGddMin(gdd_min);
        setGddMax(gdd_max);
    }, [gddData]);
    useEffect(() => {
        if (!indexForecast) return;
        const svg = select(svgRef.current);
        if (vegetationIndex != "vegetation") {
            svg.selectAll(".ndviForecastLine").style("visibility", "hidden");
            svg.selectAll(".linepattern").style("visibility", "hidden");
            svg.selectAll(".myDot.patternAlertDot.ordinaryAlert").attr("class", "myDot ordinaryAlert");
            svg.selectAll(".myDot.patternAlertDot.severeAlert").attr("class", "myDot severeAlert");
            svg.selectAll(".myDot1.selfpatternAlertDot.ordinaryAlert").attr("class", "myDot1 ordinaryAlert");
            svg.selectAll(".myDot1.selfpatternAlertDot.severeAlert").attr("class", "myDot1 severeAlert");
            svg.selectAll(".forecastdot1.indexForecastAlertDot.severeAlert").style("visibility", "hidden");
            svg.selectAll(".forecastdot1.indexForecastAlertDot.ordinaryAlert").style("visibility", "hidden");
            svg.selectAll(".lineSelfpattern").style("visibility", "hidden");

        } else {
            svg.selectAll(".ndviForecastLine").style("visibility", "visible");
            svg.selectAll(".linepattern").style("visibility", "visible");
            svg.selectAll(".myDot.severeAlert").attr("class", "myDot patternAlertDot severeAlert ");
            svg.selectAll(".myDot.ordinaryAlert").attr("class", "myDot patternAlertDot ordinaryAlert");
            svg.selectAll(".myDot1.severeAlert").attr("class", "myDot1 selfpatternAlertDot severeAlert ");
            svg.selectAll(".myDot1.ordinaryAlert").attr("class", "myDot1 selfpatternAlertDot ordinaryAlert");
            svg.selectAll(".forecastdot1.indexForecastAlertDot.severeAlert").style("visibility", "visible");
            svg.selectAll(".forecastdot1.indexForecastAlertDot.ordinaryAlert").style("visibility", "visible");
            svg.selectAll(".lineSelfpattern").style("visibility", "visible");


        }

    }, [vegetationIndex]);
    const handleClickObservedLegend = () => {
        const svg = select(svgRef.current);
        setobservedDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".line").style("visibility", "visible");
                svg.selectAll(".myDot").style("visibility", "visible");
            } else {
                svg.selectAll(".line").style("visibility", "hidden");
                svg.selectAll(".myDot").style("visibility", "hidden");
            }
            return !prevState;
        });
    };

    const handleClickSmoothedLegend = () => {
        const svg = select(svgRef.current);
        setsmoothedDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".lineSmooth").style("visibility", "visible");
            } else {
                svg.selectAll(".lineSmooth").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    const handleClickForecastLegend = () => {
        const svg = select(svgRef.current);
        setforecastDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".linepredict").style("visibility", "visible");
            } else {
                svg.selectAll(".linepredict").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    const handleClickPatternSelfLegend = () => {
        const svg = select(svgRef.current);
        setpatternSelfLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".lineSelfpattern").style("visibility", "visible");
                svg
                    .selectAll(".myDot1.severeAlert")
                    .attr("class", "myDot1 selfpatternAlertDot severeAlert ");
                svg
                    .selectAll(".myDot1.ordinaryAlert")
                    .attr("class", "myDot1 selfpatternAlertDot ordinaryAlert");
            } else {
                svg.selectAll(".lineSelfpattern").style("visibility", "hidden");
                svg
                    .selectAll(".myDot1.selfpatternAlertDot.ordinaryAlert")
                    .attr("class", "myDot1 ordinaryAlert");
                svg
                    .selectAll(".myDot1.selfpatternAlertDot.severeAlert")
                    .attr("class", "myDot1 severeAlert");
            }
            return !prevState;
        });
    };

    const handleClickPatternLegend = () => {
        const svg = select(svgRef.current);
        setpatternLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".linepattern").style("visibility", "visible");
                svg
                    .selectAll(".myDot.severeAlert")
                    .attr("class", "myDot patternAlertDot severeAlert ");
                svg
                    .selectAll(".myDot.ordinaryAlert")
                    .attr("class", "myDot patternAlertDot ordinaryAlert");
            } else {
                svg.selectAll(".linepattern").style("visibility", "hidden");
                svg
                    .selectAll(".myDot.patternAlertDot.ordinaryAlert")
                    .attr("class", "myDot ordinaryAlert");
                svg
                    .selectAll(".myDot.patternAlertDot.severeAlert")
                    .attr("class", "myDot severeAlert");
            }
            return !prevState;
        });
    };
    const handleClickRainFallLegend = () => {
        const svg = select(svgRef.current);
        setRainDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".linerainfall").style("visibility", "visible");
            } else {
                svg.selectAll(".linerainfall").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    const handleClickGddFallLegend = () => {
        const svg = select(svgRef.current);
        setGddDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".linegddfall").style("visibility", "visible");
                svg.selectAll(".gdddot").style("visibility", "visible");

            } else {
                svg.selectAll(".linegddfall").style("visibility", "hidden");
                svg.selectAll(".gdddot").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    const handleClickTempFallLegend = () => {
        const svg = select(svgRef.current);
        setTempDataLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".linetempfall").style("visibility", "visible");
                svg.selectAll(".tempdot").style("visibility", "visible");
            } else {
                svg.selectAll(".linetempfall").style("visibility", "hidden");
                svg.selectAll(".tempdot").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    const handleClickindexForecastLineLegend = () => {
        const svg = select(svgRef.current);
        setIndexForecastLineState((prevState) => {
            if (!prevState) {
                svg.selectAll(".ndviForecastLine").style("visibility", "visible");
                svg.selectAll(".forecastdot1").style("visibility", "visible");
            } else {
                svg.selectAll(".ndviForecastLine").style("visibility", "hidden");
                svg.selectAll(".forecastdot1").style("visibility", "hidden");
            }
            return !prevState;
        });
    };
    useEffect(() => {
            if (!datagraph) return;
            const svg = select(svgRef.current);
            const svgContent = svg.select(".content");
            if (!dimensions) return;
            if (!data) return;
            const {width, height} = dimensions || wrapperRef.current.getBoundingClientRect();

            let xScale;
            let concatenatedArray = [...datagraph];
            if (pattern && pattern.length > 0) {
                concatenatedArray = [...concatenatedArray, ...pattern];
            }
            if (patternSelf && patternSelf.length > 0) {
                concatenatedArray = [...concatenatedArray, ...patternSelf];
            }
            if (dataRangePredict && dataRangePredict.length > 0) {
                concatenatedArray = [...concatenatedArray, ...dataRangePredict];
            }

            const {minn, maxx} = concatenatedArray.reduce(
                (acc, obj) => {
                    acc.minn = Math.min(acc.minn, obj.value);
                    acc.maxx = Math.max(acc.maxx, obj.value);
                    return acc;
                },
                {minn: Infinity, maxx: -Infinity}
            );
            xScale = scaleTime()
                .domain(extent(concatenatedArray, (d) => new Date(d.date)))
                .range([0, width]);

            if (currentZoom) {
                const newXScale = currentZoom.rescaleX(xScale);
                xScale.domain(newXScale.domain());
            }
            const yMax = Math.max(Math.abs(minn), Math.abs(maxx));
            const yScale = scaleLinear()
                .domain([-yMax / 2, yMax *1.3])
                .range([height, 0]);

            const maxValue = Math.max(
                Math.abs(tempMin), Math.abs(tempMax),
                Math.abs(rainMin), Math.abs(rainMax),
                Math.abs(gddMin), Math.abs(gddMax)
            );
            const yScale2 = scaleLinear()
                .domain([-maxValue / 2, maxValue*1.3])
                .range([height, 0]);

            const myLine = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale(d.value))
                .curve(curveCatmullRom);
            const ndviForecastLine = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale(d.value))
                .curve(curveCatmullRom);
            const myLineSmooth = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale(d.NDVI_rolling_mean))
                .curve(curveCatmullRom);
            const rainLine = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale2(d.value))
                .curve(curveCatmullRom);
            const tempLine = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale2(d.value))
                .curve(curveCatmullRom);
            const gddLine = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale2(d.value))
                .curve(curveCatmullRom);
            const xAxis = axisBottom(xScale);
            const myLineAutoPatter = line()
                .x((d) => xScale(new Date(d.date)))
                .y((d) => yScale(d.value))
            svg.select(".x-axis")
                .attr("color", "#808080")
                .attr("transform", `translate(0, ${yScale(0)})`)
                .call(xAxis);
            svg.select(".x-axis")
                .selectAll("text")
                .attr("transform", "translate(-5,5)rotate(-45)")
                .style("text-anchor", "end");
            svg.select(".y-axis2").attr("transform", `translate(${width},0)`);
            const yAxis = axisLeft(yScale).tickFormat(d => d.toFixed(2));
            svg.select(".y-axis").attr("color", "#808080").call(yAxis);
            const yAxisRight = axisRight(yScale2).tickFormat(d => {
                if (d % 1 === 0) {
                    return d.toFixed(0) + " mm";
                }
                return d.toFixed(2) + " mm";
            });
            svg.select(".y-axis").attr("color", "#808080").call(yAxis);
            svg.select(".y-axis2").attr("color", "#808080").call(yAxisRight);
            const yAxisTemp = axisRight(yScale2).tickFormat(d => d.toFixed(2) + "°C");
            svg.select(".y-axis-temp")
                .attr("transform", `translate(${width},0)`)
                .call(yAxisTemp);
            const yAxisGdd = axisRight(yScale2).tickFormat(d => d.toFixed(2) + "°C");
            svg.select(".y-axis-gdd")
                .attr("transform", `translate(${width},0)`)
                .call(yAxisGdd);
            svgContent
                .selectAll(".line")
                .data([datagraph])
                .join("path")
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", "#6f9d2f")
                .attr("stroke-width", "2px")
                .style("visibility", observedDataLineState ? "visible" : "hidden")
                .attr("d", myLine);

            svgContent
                .selectAll(".lineSmooth")
                .data([datagraphSmooth])
                .join("path")
                .attr("class", "lineSmooth")
                .attr("fill", "none")
                .attr("stroke", "#ffc107")
                .attr("stroke-width", "2px")
                .style("visibility", smoothedDataLineState ? "visible" : "hidden")
                .attr("d", myLineSmooth);
            if (dataRangePredict) {
                svgContent
                    .selectAll(".linepredict")
                    .data([dataRangePredict])
                    .join("path")
                    .attr("class", "linepredict")
                    .attr("fill", "none")
                    .attr("stroke", "#0051ff")
                    .attr("stroke-width", "2px")
                    .style("visibility", forecastDataLineState ? "visible" : "hidden")
                    .attr("d", myLine);
            }
            if (rainData && rainData.length > 0) {
                svgContent
                    .selectAll(".linerainfall")
                    .data([rainData])
                    .call(axisRight(yScale2))
                    .join("path")
                    .attr("class", "linerainfall")
                    .attr("fill", "none")
                    .attr("stroke", "#B0BEC5")
                    .attr("stroke-width", "1.5px")
                    .style("visibility", rainDataLineState ? "visible" : "hidden")
                    .attr("d", rainLine)
                    .attr("y", 140)
                    .attr("dy", "171px")
                    .attr("dx", "205px")
                    .style("text-anchor", "end")
                    .text("Rainfall (mm)");
            }
            {/*if (gddData && gddData.length > 0 && (cultureId === "7bd57d1b-428f-4f09-b7c0-45ef023eb110" || cultureId==="a17a408e-d234-4aad-bf28-fe5c25e2eeb8"|| cultureId==="0c838d48-b13c-4a8e-be50-ba10d25b595c") )
            {
                    svgContent
                    .selectAll(".linegddfall")
                    .data([gddData])
                    .call(axisRight(yScale2))
                    .join("path")
                    .attr("class", "linegddfall")
                    .attr("fill", "none")
                    .attr("stroke", "#bdbd00")
                    .attr("stroke-width", "1.5px")
                    .style("visibility", gddDataLineState ? "visible" : "hidden")
                    .attr("d", gddLine)
                    .attr("y", 140)
                    .attr("dy", "171px")
                    .attr("dx", "205px")
                    .style("text-anchor", "end")
                    .text("Gdd (°C)");

                svgContent
                    .selectAll(".gdddot")
                    .data(gddData)
                    .join("circle")
                    .attr("class", "gdddot")
                    .attr("stroke", "#bdbd00")
                    .attr("stroke-width", "1px")
                    .attr("fill", "#bdbd00")
                    .style("visibility", gddDataLineState ? "visible" : "hidden")
                    .attr("r", "2")
                    .attr("cx", (d) => xScale(new Date(d.date)))
                    .attr("cy", (d) => yScale2(d.value))
                    .on("mouseover", function (event, d) {
                        select(this).transition().duration("100").attr("r", 5);
                        div.transition().duration(100).style("opacity", 0.6).style("display", "block");
                        div.html(`Date: ${d.date}<br>Gdd: ${parseFloat(d.value).toFixed(2).replace(/\.?0+$/, '')}°C`)
                            .style("left", event.pageX + 10 + "px")
                            .style("top", event.pageY - 30 + "px");
                    })
                    .on("mouseout", function (d, i) {
                        select(this).transition().duration("200").attr("r", 2);
                        div.transition().duration("200").style("opacity", 0).style("display", "none");
                    });
            }*/
            }
            if (tempData && tempData.length > 0) {
                svgContent
                    .selectAll(".linetempfall")
                    .data([tempData])
                    .call(axisRight(yScale2))
                    .join("path")
                    .attr("class", "linetempfall")
                    .attr("fill", "none")
                    .attr("stroke", "#ED3EF7")
                    .attr("stroke-width", "1.5px")
                    .style("visibility", tempDataLineState ? "visible" : "hidden")
                    .attr("d", tempLine)
                    .attr("y", 140)
                    .attr("dy", "171px")
                    .attr("dx", "205px")
                    .style("text-anchor", "end")
                    .text("Temperature (°C)");
                svgContent
                    .selectAll(".tempdot")
                    .data(tempData)
                    .join("circle")
                    .attr("class", "tempdot")
                    .attr("stroke", "#ED3EF7")
                    .attr("stroke-width", "1px")
                    .attr("fill", "#ED3EF7")
                    .style("visibility", tempDataLineState ? "visible" : "hidden")
                    .attr("r", "2")
                    .attr("cx", (d) => xScale(new Date(d.date)))
                    .attr("cy", (d) => yScale2(d.value))
                    .on("mouseover", function (event, d) {
                        select(this).transition().duration("100").attr("r", 5);
                        div.transition().duration(100).style("opacity", 0.6).style("display", "block");
                        div.html(`Date: ${d.date}<br>Temperature: ${d.value}°C`)
                            .style("left", event.pageX + 10 + "px")
                            .style("top", event.pageY - 30 + "px");
                    })
                    .on("mouseout", function (d, i) {
                        select(this).transition().duration("200").attr("r", 2);
                        div.transition().duration("200").style("opacity", 0).style("display", "none");
                    });
            }
            if (indexForecast && indexForecast.length > 0 && vegetationIndex === "vegetation") {
                svgContent
                    .selectAll(".ndviForecastLine")
                    .data([indexForecast])
                    .join("path")
                    .attr("class", "ndviForecastLine")
                    .attr("fill", "none")
                    .attr("stroke", "#024CAA")
                    .attr("stroke-width", "3px")
                    .style("visibility", indexForecastLineState && vegetationIndex === "vegetation" ? "visible" : "hidden")
                    .attr("d", ndviForecastLine);
                svgContent
                    .selectAll(".forecastdot1")
                    .data(indexForecast)
                    .join("circle")
                    .attr("class", (d) =>
                        ndviForecastAlertsDates.ordinaryAlerts.includes(d.date) ? "forecastdot1 indexForecastAlertDot ordinaryAlert"
                            : ndviForecastAlertsDates.severeAlerts.includes(d.date)
                                ? "forecastdot1 indexForecastAlertDot severeAlert"
                                : "forecastdot1"
                    )
                    .attr("stroke", "#024CAA")
                    .attr("stroke-width", "1px")
                    .attr("fill", "#024CAA")
                    .style("visibility", indexForecastLineState ? "visible" : "hidden")
                    .style("opacity", "0")
                    .attr("r", "1")
                    .attr("cx", (d) => xScale(new Date(d.date)))
                    .attr("cy", (d) => yScale(d.value))
                    .on("mouseover", function (event, d) {
                        select(this).transition().duration("100").attr("r", 5);
                        div
                            .transition()
                            .duration(100)
                            .style("opacity", 1)
                            .style("display", "block")
                        div
                            .html(
                                "Date : " +
                                d.date +
                                "<br>" +
                                t("NDVI") +
                                ": " +
                                d.value.toFixed(3) +
                                "<br>"
                            )
                            .style("left", event.pageX + 10 + "px")
                            .style("top", event.pageY - 30 + "px");
                    })
                    .on("mouseout", function (d, i) {
                        select(this).transition().duration("200").attr("r", 3);
                        div
                            .transition()
                            .duration("200")
                            .style("opacity", 0)
                            .style("display", "none");
                    });
            }
            if (pattern && pattern.length > 0 && vegetationIndex === "vegetation") {
                svgContent
                    .selectAll(".linepattern")
                    .data([pattern])
                    .join("path")
                    .attr("class", "linepattern")
                    .attr("fill", "none")
                    .attr("stroke", "#FF3535")
                    .attr("stroke-width", "2px")
                    .style("visibility", patternLineState ? "visible" : "hidden")
                    .attr("d", myLineSmooth);
            }


            if (patternSelf && patternSelf.length > 0 && vegetationIndex === "vegetation") {
                svgContent
                    .selectAll(".lineSelfpattern")
                    .data([patternSelf])
                    .join("path")
                    .attr("class", "lineSelfpattern")
                    .attr("fill", "none")
                    .attr("stroke", "#A66E38")
                    .attr("stroke-width", "2px")
                    .style("visibility", patternSelfLineState ? "visible" : "hidden")
                    .attr("d", myLineSmooth);
                const allAlertDates = [
                    ...selfPatternAlertsDates.ordinaryAlerts.map(alert => alert.date),
                    ...selfPatternAlertsDates.severeAlerts.map(alert => alert.date)
                ];

                svgContent
                    .selectAll(".myDot1")
                    .data(patternSelf.filter(d => allAlertDates.includes(d.date)))
                    .join("circle")
                    .attr("class", (d) =>
                        selfPatternAlertsDates.severeAlerts.some(alert => alert.date === d.date)
                            ? "myDot1 selfpatternAlertDot severeAlert"
                            : selfPatternAlertsDates.ordinaryAlerts.some(alert => alert.date === d.date)
                                ? "myDot1 selfpatternAlertDot ordinaryAlert"
                                : "myDot1"
                    )
                    .attr("stroke", "#6f9d2f")
                    .attr("stroke-width", "1px")
                    .attr("fill", (d) =>
                        selfPatternAlertsDates.severeAlerts.some(alert => alert.date === d.date)
                            ? "red"
                            : selfPatternAlertsDates.ordinaryAlerts.some(alert => alert.date === d.date)
                                ? "pink"
                                : "#6f9d2f"
                    )
                    .style("visibility", patternSelfLineState ? "visible" : "hidden") // Lier la visibilité à patternSelfLineState
                    .style("opacity", ".6")
                    .attr("r", "3")
                    .attr("cx", (d) => xScale(new Date(d.date)))
                    .attr("cy", (d) => yScale(d.NDVI_rolling_mean))
                    .on("mouseover", function (event, d) {
                        select(this).transition().duration("100").attr("r", 5);
                        div
                            .transition()
                            .duration(100)
                            .style("opacity", 1)
                            .style("display", "block");
                        div
                            .html(
                                "Date : " + d.date + "<br>" + t("Value") + ": " + parseFloat(d.NDVI_rolling_mean).toFixed(3).replace(/\.?0+$/, '') + "<br>"
                            )
                            .style("left", event.pageX + 10 + "px")
                            .style("top", event.pageY - 30 + "px");
                    })
                    .on("mouseout", function (d, i) {
                        select(this).transition().duration("200").attr("r", 3);
                        div
                            .transition()
                            .duration("200")
                            .style("opacity", 0)
                            .style("display", "none");
                    });


            }

            svgContent
                .selectAll(".myDot")
                .data(datagraph)
                .join("circle")
                .attr("class", (d) =>
                    patternAlertsDates.severeAlerts.includes(d.date) && vegetationIndex === "vegetation"
                        ? "myDot patternAlertDot severeAlert"
                        : patternAlertsDates.ordinaryAlerts.includes(d.date) && vegetationIndex === "vegetation"
                            ? "myDot patternAlertDot ordinaryAlert "
                            : "myDot"
                )
                .attr("stroke", "#6f9d2f")
                .attr("stroke-width", "1px")
                .attr("fill", "#6f9d2f")
                .style("visibility", observedDataLineState ? "visible" : "hidden")
                .style("opacity", ".6")
                .attr("r", "3")
                .attr("cx", (d) => xScale(new Date(d.date)))
                .attr("cy", (d) => yScale(d.value))
                .on("mouseover", function (event, d) {
                    select(this).transition().duration("100").attr("r", 5);
                    div
                        .transition()
                        .duration(100)
                        .style("opacity", 1)
                        .style("display", "block");
                    div
                        .html(
                            d.cloud_percentage && d.cloud_percentage == -1
                                ? "Date : " +
                                d.date +
                                "<br>" +
                                t("Value") +
                                ": " +
                                d.value +
                                "<br>" +
                                t("Cloud") +
                                " : " +
                                t("Undefined")
                                : "Date : " +
                                d.date +
                                "<br>" +
                                t("Value") +
                                ": " +
                                d.value +
                                "<br>" +
                                t("Cloud") +
                                " : " +
                                d.cloud_percentage +
                                "%"
                        )
                        .style("left", event.pageX + 10 + "px")
                        .style("top", event.pageY - 30 + "px");
                })
                .on("mouseout", function (d, i) {
                    select(this).transition().duration("200").attr("r", 3);
                    div
                        .transition()
                        .duration("200")
                        .style("opacity", 0)
                        .style("display", "none");
                });

            if (dataRangeNotifications && dataRangeNotifications.length > 0) {
                svgContent
                    .selectAll(".myDotNote")
                    .data(dataRangeNotifications)
                    .join("circle")
                    .attr("class", "myDotNote")
                    .style("visibility", forecastDataLineState ? "visible" : "hidden")
                    .attr("stroke", (d) => {
                        if (d && typeof d.abs === "number") {
                            return d.abs > 0.2 ? "#f44336" : "#ff9800";
                        }
                        return "";
                    })
                    .attr("stroke-width", "3px")
                    .attr("fill", (d) => {
                        if (d && typeof d.abs === "number") {
                            return d.abs > 0.2 ? "#f44336" : "#ff9800";
                        }
                        return "";
                    })
                    .attr("r", "3")
                    .attr("cx", (d) => xScale(new Date(d.date)))
                    .attr("cy", (d) => yScale(d.value))
                    .on("mouseover", function (event, d) {
                        select(this).transition().duration("100").attr("r", 5);
                        div
                            .transition()
                            .duration(100)
                            .style("opacity", 1)
                            .style("display", "block");
                        div
                            .html(
                                d.date
                                    ? "Date : " + d.date + "<br>" + t("Value") + ": " + d.value
                                    : ""
                            )
                            .style("left", event.pageX + 10 + "px")
                            .style("top", event.pageY - 30 + "px");
                    })
                    .on("mouseout", function (d, i) {
                        select(this).transition().duration("200").attr("r", 3);
                        div
                            .transition()
                            .duration("200")
                            .style("opacity", 0)
                            .style("display", "none");
                    });
            } else {
                svgContent.selectAll(".myDotNote").remove();
            }
            const zoomBehavior = zoom()
                .translateExtent([
                    [0, 0],
                    [width, height],
                ])
                .scaleExtent([0.3, 100])
                .on("zoom", (event) => {
                    const zoomState = event.transform;
                    setcurrentZoom(zoomState);
                });
            svg.call(zoomBehavior);
        },
        [
            svgRef,
            datagraph,
            datagraphSmooth,
            dimensions,
            currentZoom,
            observedDataLineState,
            datapredict,
            indexForecast,
            notifications,
            pattern,
            rainData,
            rainMax,
            rainMin,
            tempData,
            tempMax,
            tempMin,
            gddData,
            gddMax,
            gddMin,
            forecastDataLineState,
            ndviForecastAlertsDates,
            patternSelf,
            selfPatternAlertsDates,patternSelfLineState
        ]);
    return (
        <>
            <Grid
                item
                xs={12}
                container
                spacing={1}
                className="mt-3 mb-1 d-flex justify-content-center"
            >
                <Grid
                    item
                    className="d-flex align-items-center task-legend"
                    onClick={() => handleClickObservedLegend()}
                >
                    <SlGraph
                        style={{
                            color: observedDataLineState ? "#6f9d2f" : "#cccccc",
                            fontSize: "2rem",
                            padding: ".1rem",
                            border: "1px grey solid",
                            "border-radius": "100%",
                            marginRight: "5px"
                        }}
                    />
                    <span
                        style={{color: observedDataLineState ? "#000000" : "#cccccc"}}
                    >
            Observed {vegetationIndex}
          </span>
                </Grid>
                <Grid
                    item
                    className="d-flex align-items-center task-legend"
                    onClick={() => handleClickSmoothedLegend()}
                >
                    <SlGraph
                        style={{
                            color: smoothedDataLineState ? "#ffc107" : "#cccccc",
                            fontSize: "2rem",
                            padding: ".1rem",
                            border: "1px grey solid",
                            "border-radius": "100%",
                            marginRight: "5px"
                        }}
                    />
                    <span
                        style={{color: smoothedDataLineState ? "#000000" : "#cccccc"}}
                    >
            Smoothed {vegetationIndex}
          </span>
                </Grid>
                {pattern.length > 0 && vegetationIndex === "vegetation" && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickPatternLegend()}
                    >
                        <SlGraph
                            style={{
                                color: patternLineState ? "#FF3535" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"
                            }}
                        />
                        <span style={{color: patternLineState ? "#000000" : "#cccccc"}}>
              Pattern
            </span>
                    </Grid>
                )}

                {patternSelf.length > 0 && vegetationIndex === "vegetation" && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickPatternSelfLegend()}
                    >
                        <SlGraph
                            style={{
                                color: patternSelfLineState ? "#A66E38" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"
                            }}
                        />

                        <span style={{color: patternSelfLineState ? "#000000" : "#cccccc"}}>
              Auto Pattern
            </span>
                    </Grid>
                )}
                {rainData.length > 0 && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickRainFallLegend()}
                    >
                        <SlGraph
                            style={{
                                color: rainDataLineState ? "#B0BEC5" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"
                            }}
                        />
                        <span style={{color: rainDataLineState ? "#000000" : "#cccccc"}}>
              Rainfall
            </span>
                    </Grid>
                )}

                {tempData.length > 0 && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickTempFallLegend()}
                    >
                        <SlGraph
                            style={{
                                color: tempDataLineState ? "#ED3EF7" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"
                            }}
                        />
                        <span style={{color: tempDataLineState ? "#000000" : "#cccccc"}}>
              Temperature
            </span>
                    </Grid>
                )}
                {/*{gddData.length > 0 && (cultureId === "7bd57d1b-428f-4f09-b7c0-45ef023eb110" || cultureId==="a17a408e-d234-4aad-bf28-fe5c25e2eeb8"|| cultureId==="0c838d48-b13c-4a8e-be50-ba10d25b595c")&& (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickGddFallLegend()}
                    >
                        <SlGraph
                            style={{
                                color: gddDataLineState ? "#bdbd00" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"
                            }}
                        />
                        <span style={{color: gddDataLineState ? "#000000" : "#cccccc"}}>
                            GDD
            </span>
                    </Grid>
                )}*/}
                {dataRangePredict && dataRangePredict.length > 0 && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickForecastLegend()}
                    >
                        <SlGraph
                            style={{
                                color: forecastDataLineState ? "#0051ff" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"

                            }}
                        />
                        <span
                            style={{color: forecastDataLineState ? "#000000" : "#cccccc"}}
                        >
              {vegetationIndex} Forecast M1
            </span>
                    </Grid>
                )}
                {indexForecast.length > 0 && vegetationIndex === "vegetation" && (
                    <Grid
                        item
                        className="d-flex align-items-center task-legend"
                        onClick={() => handleClickindexForecastLineLegend()}
                    >
                        <SlGraph
                            style={{
                                color: indexForecastLineState ? "#024CAA" : "#cccccc",
                                fontSize: "2rem",
                                padding: ".1rem",
                                border: "1px grey solid",
                                "border-radius": "100%",
                                marginRight: "5px"

                            }}
                        />
                        <span style={{color: indexForecastLineState ? "#000000" : "#cccccc"}}>
              Forecast M2
            </span>
                    </Grid>
                )}
            </Grid>
            <div
                className="col-md-12 svg-container"
                ref={wrapperRef}
                style={datagraph ? {marginBottom: "40px"} : {}}
            >
                {datagraph ? (
                    <svg ref={svgRef} className="graphIndex">
                        <defs>
                            <clipPath id="MygraphPanel">
                                <rect x="0" y="0" width="100%" height="100%"/>
                            </clipPath>
                        </defs>
                        <g className="content" clipPath="url(#MygraphPanel)"></g>
                        <g className="x-axis"/>
                        <g className="x-axis-grid"/>
                        <g className="y-axis"/>
                        <g className="y-axis2"/>
                    </svg>
                ) : (
                    ""
                )}
            </div>
        </>
    );
};

export default Graph;
