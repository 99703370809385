import axios from 'axios';
import {
    TASKS_GRAPH_DATA_COMPARE_SUCCESS,
    TASKS_GRAPH_DATA_COMPARE_FAIL, TASKS_GRAPH_DATA_SELF_SUCCESS, TASKS_GRAPH_DATA_SELF_FAIL
} from "../utils/types.js";

export const getFiltredTasks = (culture, variety, density, age) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ culture, variety, density, age });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/tasks/filter/`, body, config);
        return res.data.data
    } catch (err) {

        return err
    }
}

export const getFiltredTasksGraphData = (tasks, index) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ tasks, index });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/tasks/graphdata/compare/`, body, config);
        if (res.status === 200) {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_SUCCESS,
                payload: res.data.data
            })
        } else {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASKS_GRAPH_DATA_COMPARE_FAIL
        })
        console.error('err', err); // Log the error details for debugging
        return err.response
    }

}


export const clearTaskDataGraph = () => async dispatch => {
    dispatch({
        type: TASKS_GRAPH_DATA_COMPARE_FAIL
    })

}

export const saveComparePattern = (formData) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ formData });
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/graph_data/pattern/add/`, body, config);
        if (res.status === 200) {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_SUCCESS,
                payload: res.data.data
            })
        } else {
            dispatch({
                type: TASKS_GRAPH_DATA_COMPARE_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASKS_GRAPH_DATA_COMPARE_FAIL
        })
        console.error('err', err); // Log the error details for debugging
        return err.response
    }

}

export const saveSelfPattern = (formData) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({
        formData: {
            task: formData.task,
            StartSelfDateP: formData.StartSelfDateP.toISOString(),
            EndSelfDateP: formData.EndSelfDateP.toISOString()
        }
    });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/task/self/graph_data/pattern/add/`, body, config);
        if (res.status === 200) {
            dispatch({
                type: TASKS_GRAPH_DATA_SELF_SUCCESS,
                payload: res.data.data
            })
        } else {
            dispatch({
                type: TASKS_GRAPH_DATA_SELF_FAIL
            })
        }
        return res
    } catch (err) {
        dispatch({
            type: TASKS_GRAPH_DATA_SELF_FAIL
        })
        console.error('err', err);
        return err.response
    }
}
