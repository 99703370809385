import { Container, Grid, Slider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Histogram from "./histogram";
import { useDispatch, useSelector } from "react-redux";
import {
  getHistogramProductivity,
  getHistogramRange,
  getHistogramStatic,
} from "../../../actions/histogram";
import {
  SET_ACTIVE_TAB_SUCCESS,
  SET_HISTOGRAM_INTERVAL_SUCCESS,
} from "../../../utils/types";
import HistogramProductivity from "./histogramProductivity";

const HistogramTab = () => {
  const { t } = useTranslation();

  const [staticMin, setstaticMin] = useState(null);
  const [staticMax, setstaticMax] = useState(null);
  const density_id = useSelector((state) => state.mapReducer.densityDetails);
  const index = useSelector((state) => state.mapReducer.checkboxLayers);
  const minPercentile = useSelector((state) => state.histogramReducer.min);
  const maxPercentile = useSelector((state) => state.histogramReducer.max);
  const histogram = useSelector((state) => state.histogramReducer.histogram);
  const activeTab = useSelector((state) => state.histogramReducer.activeTab);
  const loading = useSelector((state) => state.histogramReducer.loading);
  const selected_date = useSelector((state) => state.mapReducer.SelectedDate);
  const taskId = useSelector((state) => state.mapReducer.taskIdDetails);

  const [zoneNbr, setzoneNbr] = useState(5);
  const dispatch = useDispatch();
  const handleTabClick = (tab) => {
    dispatch({
      type: SET_ACTIVE_TAB_SUCCESS,
      payload: tab,
    });
  };
  const marks = [
    {
      value: 5,
      label: "5",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 11,
      label: "11",
    },
    {
      value: 12,
      label: "12",
    },
    {
      value: 13,
      label: "13",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 15,
      label: "15",
    },
  ];
  useEffect(() => {

    if (!activeTab) return;
    if (!index) return;
    if (!histogram || histogram.length === 0) return;
    console.log("histogram tab", histogram);
    if (activeTab === 2) {
      dispatch(getHistogramStatic(index, density_id)).then((res) => {});

    }
    if (activeTab === 1) {
      const interval = [
        parseFloat(histogram.percentile[0]),
        parseFloat(histogram.percentile[1]),
      ];
      dispatch({
        type: SET_HISTOGRAM_INTERVAL_SUCCESS,
        payload: interval,
      });
    }
    if (activeTab === 3) {
      dispatch(
        getHistogramProductivity(
          taskId,
          selected_date.date,
          selected_date.provider,
          index,
          zoneNbr
        )
      ).then((res) => {
        if (res.state === 200) {
          console.log("res", res);
        }
      });
    }
  }, [activeTab, histogram, index]);
  const onChangeSlider = (e, value) => {
    console.log("value", value);
    setzoneNbr(value);
    dispatch(
      getHistogramProductivity(
        taskId,
        selected_date.date,
        selected_date.provider,
        index,
        value
      )
    ).then((res) => {
      if (res.state === 200) {
        console.log("res", res);
      }
    });
  };
  // useEffect(() => {
  //   console.log("histogram.length", histogram.length);
  //   if (!histogram.percentile) return;
  //   if (!index) return;
  //   if (!density_id) return;
  //   console.log("clicked");
  //   if (activeTab === 2 && index && density_id) {
  //     dispatch(getHistogramStatic(index, density_id));
  //   }
  //   if (activeTab == 1) {
  //     const interval = [
  //       parseFloat(histogram.percentile[0]),
  //       parseFloat(histogram.percentile[1]),
  //     ];
  //     dispatch({
  //       type: SET_HISTOGRAM_INTERVAL_STATIC_SUCCESS,
  //       payload: interval,
  //     });
  //   }
  // }, [histogram, activeTab, index, density_id, dispatch]);
  return (
    <React.Fragment>
      <Grid container sx={{ flex: 1, p: 1 }}>
        <ul
          className="nav nav-tabs nav-tabs-histogram nav-justified w-100"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className={`nav-link navlink ${activeTab === 1 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#dynamic"
              role="tab"
              onClick={() => handleTabClick(1)}
            >
              <span className="d-none d-sm-block">{t("DYNAMIC")}</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link navlink ${activeTab === 2 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#static"
              role="tab"
              onClick={() => handleTabClick(2)}
            >
              <span className="d-none d-sm-block">{t("STATIC")}</span>
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link navlink ${activeTab === 3 ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#productivity"
              role="tab"
              onClick={() => handleTabClick(3)}
            >
              <span className="d-none d-sm-block">{t("PRODUCTIVITY")}</span>
            </a>
          </li>
        </ul>
      </Grid>
      <Grid>
        <div
          className={`tab-pane ${activeTab === 1 ? "active" : ""}`}
          id="dynamic"
          role="tabpanel"
        >
          <Grid>
            {(activeTab === 1 || activeTab == 2) && histogram ? (
              <Histogram />
            ) : (
              ""
            )}
            {activeTab === 3 && histogram ? (
              <Grid>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <Typography id="input-slider" gutterBottom>
                      Zone number
                    </Typography>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-11">
                    <Slider
                      aria-label="Temperature"
                      defaultValue={3}
                      // getAriaValueText={valuetext}
                      valueLabelDisplay="on"
                      marks={marks}
                      min={5}
                      max={15}
                      step={1}
                      disabled={loading}
                      value={zoneNbr}
                      onChange={onChangeSlider}
                    />
                  </div>
                </div>
                <HistogramProductivity />
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          {/* <div
            className={`tab-pane ${activeTab === 2 ? "active" : ""}`}
            id="static"
            role="tabpanel"
          >
            <Grid>
              {activeTab === 2 ? (
                <Histogram tab={2} staticMin={-1} staticMax={1} />
              ) : (
                ""
              )}
            </Grid>
          </div>
          <div
            className={`tab-pane ${activeTab === 3 ? "active" : ""}`}
            id="static"
            role="tabpanel"
          >
            <Grid>{activeTab === 3 ? <Histogram tab={3} /> : ""}</Grid>
          </div> */}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default HistogramTab;
